import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get } from 'lodash'
import Link from 'next/link'
import Image from 'next/image'

import Typography from '../typography'

import { useBreakpoint } from '../../../hooks/breakpoint'

import GuateAprendeIcon from '../../../public/img/bantrab/logo-bannu-03.svg'
import styles from '../../../styles/bantrab/components/home.module.scss'

const BG_COLOR_MAP = {
  blog: 'bg-green-600',
  ebook: 'bg-turquoise-600',
  podcast: 'bg-red-600',
  webinar: 'bg-yellow-600'
}

const TITLE_COLOR_MAP = {
  blog: 'text-green-300',
  ebook: 'text-turquoise-300',
  podcast: 'text-red-300',
  webinar: 'text-yellow-300'
}

const URL_MAP = {
  blog: '/blog',
  podcast: '/recursos/podcasts',
  ebook: '/recursos/ebooks',
  webinar: '/webinars'
}

const Features = ({ title, highlightedTitleText, features, ...attrs }) => {
  const breakpoint = useBreakpoint()

  title = title.replace(highlightedTitleText, '')

  attrs.className = classNames(
    attrs.className,
    [
      'relative',
      'bg-turquoise-50',
      'py-20',
      'md:py-24',
      'md:bg-white'
    ],
    {
      [styles.features]: true
    }
  )

  return <section {...attrs}>
    <div
      className={
        classNames([
          'relative',
          'container',
          'px-4'
        ])
      }>
      <div
        className="grid grid-cols-5 gap-16 mb-12">
        <div
          className="relative z-0 text-center md:text-right col-span-5 md:col-span-3 lg:col-span-3">

          <div
            className={breakpoint.mdAndUp ? 'text-right' : 'text-center'}>
            <Typography
              tag={'span'}
              weight={'black'}
              size={breakpoint.mdAndUp ? 'six' : 'four'}
              className={'text-white bg-turquoise-600 inline-block px-4 transform -translate-x-1/4 translate-y-4 relative z-0'}>
              {title}
            </Typography>
            <br />
            <Typography
              tag={'span'}
              weight={'black'}
              size={breakpoint.mdAndUp ? 'eight' : 'six'}
              className={'text-yellow-500 inline-block relative z-1'}>
              {highlightedTitleText}
            </Typography>
          </div>

          <GuateAprendeIcon
            className="absolute z-0 -top-16 -right-16 transform translate-x-full"
            width={256}/>
        </div>
      </div>

      <div
        className={
          classNames([
            'flex',
            'flex-row',
            'flex-wrap',
            'justify-center',
            '-mx-4'
          ])
        }>
        {
          features.map((feature, index) => {
            const bgColor = get(BG_COLOR_MAP, feature.featureType, 'bg-green-600')
            const titleColor = get(TITLE_COLOR_MAP, feature.featureType, 'bg-white')
            const featureUrl = get(URL_MAP, feature.featureType, '#')

            return <Link
              key={`home-features-${index}`}
              href={featureUrl}>
              <a
                className={
                  classNames([
                    'relative',
                    'flex',
                    'flex-col',
                    'items-end',
                    'bg-no-repeat',
                    'bg-center',
                    'bg-cover',
                    'm-4',
                    'overflow-hidden',
                    'cursor-pointer'
                  ])
                }
                style={{
                  borderRadius: '2.5rem 2.5rem 2.5rem 0',
                  flexBasis: breakpoint.lgAndUp ? 'calc(25% - 2rem)' : (breakpoint.mdAndUp ? 'calc(50% - 2rem)' : '100%')
                }}>
                <div
                  className={
                    classNames([
                      'w-full',
                      'bg-no-repeat',
                      'bg-center',
                      'bg-cover',
                      'relative'
                    ])
                  }
                  style={{
                    borderRadius: '2.5rem 2.5rem 0 0',
                    height: '18rem',
                    backgroundImage: `url(${feature.image.meta.downloadUrl})`
                  }}>
                </div>
                <div
                  className={
                    classNames([
                      'relative',
                      'p-6',
                      'w-full',
                      'relative',
                      bgColor
                    ])
                  }>
                  <Typography
                    weight={'black'}
                    size={'three'}
                    className={
                      classNames([
                        titleColor,
                        'mb-1',
                        'h-12',
                        'flex',
                        'flex-row',
                        'items-center'
                      ])
                    }>
                    {feature.title}
                    {
                      feature.image
                        ? <span
                          className="ml-4">
                          <Image
                            alt={feature.icon.title}
                            src={feature.icon.meta.downloadUrl}
                            width={'48px'}
                            height={'48px'}
                          />
                        </span>
                        : null
                    }
                  </Typography>
                  <Typography
                    size={'two'}
                    className={'text-white'}>
                    {feature.text}
                  </Typography>
                </div>
              </a>
            </Link>
          })
        }
      </div>
    </div>
  </section >
}

Features.propTypes = {
  title: PropTypes.string,
  highlightedTitleText: PropTypes.string,
  features: PropTypes.array
}
export default Features
